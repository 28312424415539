let oExports = {};
oExports.getOurServices = () => {
    var oOurServices = [
        {
            "Title": "Uygulama ve Sistem Destek Hizmetleri",
            "Description": "Hizmetimizin amacı, iş ortağımızın SAP çözümleri uygulanmış iş süreçlerinde, Detay Teknoloji deneyim ve bilgi birikimi çerçevesinde ihtiyaç duyulan SAP danışmanlık destek hizmetinin sağlanmasıdır."
        }, {
            "Title": "E-Dönüşüm Çözümleri",
            "Description": "Ar-Ge merkezinde TÜBİTAK tarafından desteklenen yenilikçi çözümler geliştiren Detay Teknoloji, e-dönüşüm kapsamında e-Fatura, e-Defter, e-Arşiv, e-İrsaliye, e-İmza, e-Mutabakat, e-Reçete, e-SGK, e-Sağlık, e-Hesap Özeti gibi uygulamaları Türkiye pazarına sunuyor."
        }, {
            "Title": "SAP Teknoloji Danışmanlığı",
            "Description": "Bir sistemin başarıyla hayata geçebilmesi için, sağlıklı bir planlama süreci sonunda, sağlam temeller atmak gerekir. Bu sistemin yaşayabilmesi için de ona düzenli bakmak, sorunlarını çözmek ve yeni oluşacak ihtiyaçlarını gidermek gerekmektedir."
        }, {
            "Title": "Fiori, UI5, İş ve Mobil Uygulamalar",
            "Description": "Fiori uygulamalarında öncü olan Detay Teknoloji, kullanıcı deneyimini ön plana alarak profesyonel araçlar sunar."
        }, {
            "Title": "SAP Uygulama Danışmanlığı",
            "Description": "Detay Teknoloji Kurumsal Kaynak Planlama, İnsan Kaynakları Yönetimi, Müşteri İlişkileri Yönetimi, Ürün Yaşam Döngüsü Yönetimi, Tedarik Zinciri Yönetimi, İş Süreçleri Yönetimi , Veritabanı, Veri Ambarı, SAP HANA, S/4HANA, Mobil çözümler, İş Zekası, Kurumsal Performans Yönetimi ve bulut tabanlı SAP çözümleri konularında uygulama danışmanlığı hizmetleri sunmaktadır."
        }, {
            "Title": "Dış Kaynak Kullanımı",
            "Description": "Detay Teknoloji’nin SAP alanındaki 20 yıllık deneyimi ve Türkiye’nin en iyi uzman ekiplerinden birine sahip olması ile birlikte şirketlerin iş uygulamalarının yönetimini kendisi yaparak müşterilerine büyük kolaylık ve maliyet avantajı sunmaktadır."
        }
    ];
    return oOurServices;
};
export default oExports;
