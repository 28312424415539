<template>
  <div class="">
    <v-row class="contact justify-center">
      <div class="banner">
        <v-container class="white--text">
          <v-row no-gutters class="justify-center">
            <div class="text-h4 font-weight-bold py-16">Bize Ulaşın</div>
          </v-row>
        </v-container>
      </div>
    </v-row>
    <v-container class="contact-form my-8">
      <v-row class="form">
        <v-col class="">
          <v-row no-gutters>
            <v-col cols="12">
              <div class="text-h5 font-weight-bold mb-8">
                Bizimle İletişime Geçin
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-text-field
                background-color="grey lighten-5"
                filled
                rounded
                v-model="firstname"
                :rules="nameRules"
                label="Ad"
                required
                class="text-field mr-6"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-text-field
                background-color="grey lighten-5"
                filled
                rounded
                v-model="lastname"
                :rules="nameRules"
                label="Soyad"
                required
              ></v-text-field> </v-col
          ></v-row>

          <v-row no-gutters
            ><v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-text-field
                background-color="grey lighten-5"
                filled
                rounded
                v-model="email"
                :rules="emailRules"
                label="E-mail"
                class="text-field mr-6"
                required
              ></v-text-field> </v-col
            ><v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-text-field
                background-color="grey lighten-5"
                filled
                rounded
                v-model="phone"
                :rules="nameRules"
                label="Telefon"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <v-textarea
                background-color="grey lighten-5"
                filled
                rounded
                label="Açıklama"
                value=""
                no-resize
              ></v-textarea> </v-col
          ></v-row>
          <v-row class="text-end" no-gutters>
            <v-col>
              <v-btn
                color="lightblue"
                rounded
                elevation="0"
                style="color: white"
                >Gönder</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col
          class="contact-info d-flex flex-column align-end"
          cols="12"
          sm="6"
          md="6"
          lg="6"
          xl="6"
        >
          <div class="">
            <div class="text-h5 font-weight-bold mb-6">İletişim Bilgileri</div>

            <div class="d-flex">
              <div class="mr-2">
                <i class="fas fa-map-marker-alt icon"></i>
              </div>
              <div class="">
                <span class="font-weight-bold"> Adres </span>
                <p>Kardeşler Mahallesi 51-52. Sokak No: 4A</p>
              </div>
            </div>
            <div class="d-flex my-2">
              <div class="mr-2">
                <i class="fas fa-phone-alt text-gray-400 text-xl icon"></i>
              </div>
              <div class="">
                <span class="font-weight-bold"> Telefon </span>
                <p>0 (346) 502 56 66</p>
              </div>
            </div>
            <div class="d-flex">
              <div class="mr-2">
                <i class="fas fa-envelope text-gray-400 text-xl icon"></i>
              </div>
              <div class="">
                <span class="font-weight-bold"> Email</span>
                <p>info@detaysoft.com</p>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <Map />
  </div>
</template>

<script>
import Map from "../components/Map.vue";
export default {
  components: {
    Map,
  },
  data: () => ({
    valid: false,
    firstname: "",
    lastname: "",
    phone: "",
    statement: "",
    nameRules: [(v) => !!v || "Zorunlu alan"],
    email: "",
    emailRules: [
      (v) => !!v || "Zorunlu alan",
      (v) => /.+@.+/.test(v) || "Geçerli bir email adresi giriniz",
    ],
  }),
};
</script>

<style scoped>
.banner {
  width: 100%;
  min-width: auto;
  position: relative;
  overflow: hidden;
}
.contact {
  background-image: url("../assets/img/slider1rotate.png");
}
.icon {
  color: #a8a29e;
  font-size: 20px;
}
@media screen and (max-width: 600px) {
  .contact-info {
    align-items: flex-start !important;
    margin-top: 20px;
  }
}
@media screen and (max-width: 960px) {
  .text-field {
    margin-right: 0 !important;
  }
}
@media screen and (min-width: 1900px) {
  .contact-form {
    padding: 0 150px;
  }
}
</style>