<template>
  <v-container>
    <v-row class="header white align-center mb-1">
      <v-col>
        <router-link to="/">
          <img
            :src="require('../assets/img/DetayTeknoloji_Logo.png')"
            alt=""
            style="max-width: 250px; vertical-align: middle"
          />
        </router-link>
      </v-col>
      <v-col class="header-bottom">
        <div class="d-flex justify-end">
          <router-link
            to="/"
            active-class="active-class"
            exact
            class="link pb-1"
            >Anasayfa</router-link
          >
          <router-link
            to="/About"
            active-class="active-class"
            exact
            class="mx-5 link pb-1"
            >Hakkımızda</router-link
          >
          <router-link
            to="/Contact"
            active-class="active-class"
            exact
            class="link pb-1"
            >İletişim</router-link
          >
        </div>
      </v-col>
      <v-col class="mySidenav">
        <div id="mySidenav" class="sidenav">
          <a href="javascript:void(0)" @click="closeNav()">
            <span> &times;</span>
          </a>
          <div class="sidebar-route">
            <router-link
              to="/"
              active-class="side-active-class"
              exact
              class="side-class"
              >Anasayfa</router-link
            >
            <router-link
              to="/About"
              active-class="side-active-class"
              exact
              class="side-class"
              >Hakkımızda</router-link
            >
            <router-link
              to="/Contact"
              active-class="side-active-class"
              exact
              class="side-class"
              >İletişim</router-link
            >
          </div>
        </div>
        <span
          class="bars"
          style="cursor: pointer; float: right"
          @click="openNav()"
          ><i
            class="fas fa-bars side-active-class"
            style="font-size: 1.875rem"
          ></i>
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  methods: {
    openNav() {
      document.getElementById("mySidenav").style.width = "250px";
      document.getElementById("mySidenav").style.boxShadow =
        "rgba(14, 30, 37, 0.32) 0px 2px 16px 10px";
    },
    closeNav() {
      document.getElementById("mySidenav").style.width = "0";
      document.getElementById("mySidenav").style.boxShadow = "none";
    },
  },
};
</script>

<style scoped lang="scss">
/* @media screen and (max-width: 450px) {
  .banner {
    min-width: auto;
  }
} */
.link {
  color: #0000008a;
}
.active-class {
  color: #036e9a;
  border-bottom: 3px solid #036e9a;
}
.side-active-class {
  color: #036e9a !important;
}
@media screen and (min-width: 1900px) {
  .header {
    padding: 0 130px;
  }
}
@media only screen and (max-width: 600px) {
  .header-bottom {
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  .mySidenav {
    display: none;
  }
  .bars {
    display: none;
  }
}
.sidenav {
  position: fixed;
  z-index: 2;
  height: 100%;
  width: 0;
  top: 0;
  right: 0;
  background-color: #fafaf9;
  overflow: hidden;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.sidebar-route {
  margin-top: 3px;
}
.side-class {
  border-bottom: 1px solid #e7e5e4;
}
.side-class:hover {
  background-color: #036e9a;
  color: white !important;
}
.sidenav a {
  position: relative;
  top: 0;
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 18px;
  color: #575454;
  display: block;
  transition: 0.3s;
  span {
    font-size: 36px;
  }
}
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}
</style>