<template>
  <div class="home">
    <v-row class="slider justify-center pt-16 px-3">
      <div class="banner">
        <div class="particle-container">
          <vue-particles
            color="#ffffff"
            linesColor="#ffffff"
            :moveSpeed="4"
            :particlesNumber="150"
          ></vue-particles>
        </div>
        <v-container class="white--text slider-content">
          <v-row>
            <v-col class="mr-16">
              <div class="header-banner-text text-h2 font-weight-bold">
                SAP ve Non SAP Çözümler
                <div class="vue-typed">
                  <vue-typed
                    :loop="true"
                    :typeSpeed="50"
                    :backSpeed="50"
                    :showCursor="true"
                    :cursorChar="'|'"
                    :strings="['Geliştiriyoruz.', 'Üretiyoruz.']"
                  >
                    <span
                      class="typing"
                      :class="{
                        'mt-1': $vuetify.breakpoint.smAndDown,
                        'mt-4': $vuetify.breakpoint.mdAndUp,
                      }"
                    ></span>
                  </vue-typed>
                </div>
              </div>
              <p class="text-h5 font-weight-light py-5">
                Kurumsal yapılara uygun ve ihtiyaçlara yönelik SAP ve Non SAP
                çözümler geliştirerek sektörü kaliteli ürünlerimizle besliyoruz.
              </p>
              <v-btn v-show="false" elevation="0" class="buttonTextColor--text">
                BİLGİ AL
              </v-btn>
            </v-col>
            <v-col
              class="
                banner-img
                d-flex
                justify-end
                d-xs-none d-sm-none d-md-flex d-lg-flex
                slide-top
              "
            >
              <v-img
                width="400"
                :src="require('../assets/img/BannerImg02.png')"
              ></v-img>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-row>
    <v-container>
      <v-row class="boxes-header text-center my-16">
        <v-col class="d-flex flex-column align-center">
          <div class="text-h4">Yüzde Yüz Türk Sermayeli</div>
          <div class="text-h6 font-weight-light mb-1">
            Teknoloji Danışmanlığı Şirketi
          </div>
          <div class="text-border"></div>
        </v-col>
      </v-row>
      <v-row class="text-center mb-16">
        <v-row class="boxes">
          <v-col
            v-for="(item, index) in factoryInfoBoxes"
            :key="index"
            cols="12"
            xl="4"
            lg="4"
            md="4"
            sm="12"
            xs="12"
            class="box"
          >
            <div>
              <div style="min-height: 100px; width: 100px" class="mx-auto">
                <!-- <img :src="require('../assets/svg/website.svg')" alt="" /> -->
                <img :src="require(`@/assets/svg/${item.SvgSrc}`)" alt="" />
              </div>
              <div class="text-h5 mb-2">{{ item.Title }}</div>
            </div>
            <p class="text--secondary">{{ item.Description }}</p>
          </v-col>
        </v-row>
      </v-row>
    </v-container>
    <v-row class="arge justify-center">
      <div class="banner">
        <v-container class="white--text">
          <v-row no-gutters>
            <v-col cols="12" xl="8" lg="8" md="12" sm="12" class="py-16 px-6">
              <div class="text-h4 font-weight-bold pb-5">
                Detay Teknoloji ArGe Merkezi
              </div>
              <p class="text-h6 font-weight-light">
                2015 yılı itibari ile Sivas Cumhuriyet TEKNOKENT bünyesinde
                faaliyete başlayan Detay Teknoloji, Yazılım ve Danışmanlık
                alanında hizmet vermektedir. % 100 Türk sermayeli en büyük SAP
                çözüm ortağı olan Detaysoft ile işbirliği içerisinde Türkiye
                pazarının ihtiyaçlarını göz önünde bulundurarak geliştirdiği
                çözümleri pazara sunmaktadır.
              </p>
            </v-col>
            <v-col
              cols="12"
              xl="4"
              lg="4"
              md="12"
              sm="12"
              class="banner-img d-xs-none d-sm-none d-md-none d-lg-flex"
            >
              <div class="arge-img-container py-16 pr-3">
                <div class="arge-img">
                  <img :src="require('../assets/img/ArgeBannerImg.png')" />
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-row>
    <v-container>
      <v-row class="justify-center align-center my-16">
        <v-col class="services">
          <v-row class="align-center mb-16 flex-column"
            ><div class="text-h4">Hizmetlerimiz</div>

            <div class="text-border"></div
          ></v-row>
          <v-row
            v-for="(item, index) in ourServices"
            :key="index"
            class="service py-5 px-16 my-5"
          >
            <div>
              <div class="text-h5 mb-3">
                {{ item.Title }}
              </div>
              <div class="text-h7 font-weight-light">
                {{ item.Description }}
              </div>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import FactoryInfoBoxes from "@/data/FactoryInfoBoxes.js";
import OurServices from "@/data/OurServices.js";
import VueParticles from "vue-particles/src/vue-particles/vue-particles.vue";
import VueTypedJs from "vue-typed-js/src/components/VueTypedJs.vue";

export default {
  name: "Home",
  components: {
    "vue-particles": VueParticles,
    "vue-typed": VueTypedJs,
  },
  data() {
    return {
      factoryInfoBoxes: [],
      ourServices: [],
    };
  },
  mounted() {
    var oFactoryInfoBoxes = FactoryInfoBoxes.getFactoryInformations();
    var oOurServices = OurServices.getOurServices();
    this.factoryInfoBoxes = oFactoryInfoBoxes;
    this.ourServices = oOurServices;
  },
};
</script>

<style scoped lang="scss">
.home {
  position: relative;
  z-index: 1;
}
.banner {
  width: 100%;
  min-width: auto;
  position: relative;
  overflow: hidden;
  .particle-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 0;
  }
}

.vue-typed {
  height: 68px;
}
.slider {
  background-image: url("../assets/img/slider1.png");
  .slider-content {
    z-index: 1;
  }
}

.box {
  border-left: 1px solid rgb(218, 214, 214);
  border-bottom: 1px solid rgb(218, 214, 214);
  padding-top: 30px !important;
  &:first-child,
  &:nth-child(4) {
    border-left: none;
  }
  &:nth-child(4),
  &:nth-child(5),
  &:nth-child(6) {
    border-bottom: none;
  }
}

.arge {
  background-image: url("../assets/img/slider1rotate.png");
}

.service {
  background-color: #f7f7f7;
  border-radius: 3px;
  transition: all 0.3s ease;
  &:hover {
    color: white;
    background: linear-gradient(to right, #060634, #00d8ff);
  }
}

.text-border {
  width: 150px;
  margin-top: 10px;
  border-bottom: 5px solid transparent;
  background-image: url(../assets/img/slider1.png);
  background-position: center center;
  border-radius: 50px;
}

.arge-img-container {
  position: relative;
  width: 100%;
  height: 100%;
  .arge-img {
    width: 120%;
    position: absolute;
    bottom: -20px;
    right: 0;
    img {
      width: 100%;
    }
    
  }
}
.slide-top {
      animation: slide-top 2s ease-in-out infinite alternate-reverse both;
    }
@keyframes slide-top {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-30px);
  }
}

@media screen and (max-width: 600px) {
  .slider {
    padding-top: 20px !important;
  }
  .box {
    border: 0 !important;
    border-bottom: 1px solid rgb(218, 214, 214) !important;
  }
  .header-banner-text {
    font-size: 40px !important;
    margin-right: 0 !important;
  }
  .banner-img {
    display: none !important;
  }
}

@media screen and (min-width: 600px) and (max-width: 960px) {
  .box {
    border: 0 !important;
    border-bottom: 1px solid rgb(218, 214, 214) !important;
  }
}
@media screen and (max-width: 960px) {
  .boxes {
    margin: 0 50px;
  }
}
@media screen and (min-width: 1900px) {
  .banner {
    padding: 0 200px;
  }
  .boxes,
  .services {
    padding: 0 150px;
  }
}
@media screen and (max-width: 400px) {
  .boxes-header {
    margin-bottom: 0 !important;
  }
}
</style>
