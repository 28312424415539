<template>
  <div class="">
    <v-row class="about justify-center mb-3">
      <div class="banner ">
        <v-container class="white--text">
          <v-row no-gutters class="justify-center">
            <div class="text-h4 font-weight-bold py-16">Biz Kimiz</div>
          </v-row>
        </v-container>
      </div>
    </v-row>
    <v-container class="about-text">
      <v-row>
        <v-col class="grey--text px-3 pb-10">
          <p style="text-align: justify">
            2015 yılı itibari ile Sivas Cumhuriyet TEKNOKENT bünyesinde
            faaliyete başlayan Detay Teknoloji, Yazılım ve Danışmanlık alanında
            hizmet vermektedir. % 100 Türk sermayeli en büyük SAP çözüm ortağı
            olan Detaysoft ile işbirliği içerisinde Türkiye pazarının
            ihtiyaçlarını göz önünde bulundurarak geliştirdiği çözümleri pazara
            sunmaktadır. ERP entegre mobile ve web yazılım ürünleri ve analitik
            çözümler geliştiren firmamız sosyal iletişim ve iş yönetim platformu
            olan B-Net, e-dönüşüm kapsamında e-fatura, e-defter ve e-arşiv,
            analitik uygulamalar alanında ise B-Pack, QuIK Pack, ve Market
            Intelligence çözümlerini Türkiye pazarına sunmuştur. Detay Teknoloji
            2017 sonu itibari ile KOSGEB Ar-Ge, İnovasyon ve Endüstriyel
            Uygulama Destek Programı kapsamında “Kurumsal Anında Mesajlaşma
            Sistemleri ve Doküman Yönetim Sistemlerinin Gerçek Zamanlı Yönetimi
            ve Paylaşım Sistemi” projesini ve Kobi Gelişim (KOBİGEL) Destek
            Programı kapsamında “Melez Yazılım Geliştirme Tekniklerinin
            Öğretilmesi ve Kurumsal Kaynak Planlama Platformlarına Entegre İş
            Yönetim Uygulamasının Geliştirilmesi” projesini başarıyla
            tamamlamıştır. Detay Teknoloji Ar-Ge çalışmalarına ve yatırımlarına
            Anadolu’nun en büyük yazılım ekibini kurma yolunda hızla devam
            etmektedir.
          </p>
          <p style="text-align: justify">
            Detay Teknoloji olarak web teknolojileri, yazılım geliştirme,
            yazılım danışmanlığı, sap danışmanlık hizmeti, e-fatura, e-arşiv,
            e-irsaliye, e-çözümler, özel entegratörlük hizmetleri
            faaliyetlerinde;
          </p>
          <p style='font-weight: bold;'>BGYS KAPSAMI</p>
          <p style="text-align: justify">
            Ar-Ge Tasarım Ve Yenilik Projeleri, Web Teknolojileri, Yazılım Geliştirme, Sap Danışmanlık Hizmetleri, E-Fatura, E-Arşiv, E-Defter, E-İrsaliye Ve Tüm E-Devlet Özel Entegratör Hizmetleri İçin Bilişim Güvenliğinin Sağlanması
          </p>
          <p style='font-weight: bold;'>KYS KAPSAMI</p>
          <p style="text-align: justify">
            Ar-Ge Tasarım Ve Yenilik Projeleri, Web Teknolojileri, Yazılım Geliştirme, Sap Danışmanlık Hizmetleri, E-Fatura, E-Arşiv, E-Defter, E-İrsaliye Ve Tüm E-Devlet Özel Entegratör Hizmetleri
          </p>
          <p style='font-weight: bold;'>İSYS KAPSAMI</p>
          <p style="text-align: justify">
            Ar-Ge Tasarım Ve Yenilik Projeleri, Web Teknolojileri, Yazılım Geliştirme, Sap Danışmanlık Hizmetleri, E-Fatura, E-Arşiv, E-Defter, E-İrsaliye Ve Tüm E-Devlet Özel Entegratör Hizmetleri İçin İş Sürekliliğinin Sağlanması
          </p>
          <p style='font-weight: bold;'>HYS KAPSAMI</p>
          <p style="text-align: justify">
            Ar-Ge Tasarım Ve Yenilik Projeleri, Web Teknolojileri, Yazılım Geliştirme, Sap Danışmanlık Hizmetleri, E-Fatura, E-Arşiv, E-Defter, E-İrsaliye Ve Tüm E-Devlet Özel Entegratörlük için Bilgi Teknolojilerinde Hizmet Yönetiminin Sağlanması
          </p>
          <p style='font-weight: bold;'>
            Yukarıda belirtilen Entegre Yönetim Sistemi kapsamında;
          </p>
          <ul style="list-style-type: square;">
              <li>
                <p style="text-align: justify">
                  Bilginin sadece yetkili kişiler tarafından erişilebilir olmasını, yetkisiz değiştirmelerden korunmasını ve değiştirildiğinde farkına varılmasını, yetkili kullanıcılar tarafından gerek duyulduğu an kullanılabilir olmasını sağlamak,
                </p>
              </li>
              <li>
                <p style="text-align: justify">
            Hizmet verdiğimiz kişi ve/veya Kurumlara ait hiçbir bilgiyi 3. şahıslarla paylaşmamak,
          </p>
              </li>
              <li>
                <p style="text-align: justify">Kişisel ve elektronik iletişimde şirkete ait bilginin gizliliğini, bütünlüğünü sağlamak,</p>
              </li>
              <li>
                <p style="text-align: justify">
            Şirket içi bilgi kaynaklarını (doküman, duyuru vb.) yetkisiz olarak 3. Kişilere iletmemek,
          </p>
              </li>
              <li>
                <p style="text-align: justify">
            Detay Teknolojileri müşterileri, iş ortakları, tedarikçiler veya diğer üçüncü kişilere ait bilgilerin gizliliğini, bütünlüğünü ve erişilebilirliğini korumak,
          </p>
              </li>
              <li>
                <p style="text-align: justify">
            Şirket bilgi sistemlerini altyapılarını mevzuata aykırı faaliyetler amacıyla kullanmamak,
          </p>
              </li>
              <li>
                <p style="text-align: justify">
            Bilgi Güvenliği ihlal olaylarını raporlamalı, bu ihlalleri önleyecek önlemler almak,
          </p>
              </li>
              <li>
                <p style="text-align: justify">
            Firmamıza ait bilgilerin doğruluğunu ve bütünlüğünü sağlamak,
          </p>
              </li>
              <li>
                <p style="text-align: justify">
            Virüs ve zararlı yazılımların kaynağı olmamak ve bu tür faaliyetleri engellemek için gerekli tedbirleri almak,
          </p>
              </li>
              <li>
                <p style="text-align: justify">
            Bilgi güvenliği ile ilgili yasal olarak belirlenmiş ve belirlenecek tüm kurallara uymak,
          </p>
              </li>
              <li>
                <p style="text-align: justify">
            Temel ve destekleyici iş faaliyetlerinin en az kesinti ile devam etmesini sağlamak,
          </p>
              </li>
          </ul>
          <p style="text-align: justify">
            Hususlarını Entegre Yönetim Sistemini Politikamız olarak belirlediğimizi ve bu hususların eksiksiz uygulanması konusunda büyük hassasiyet göstereceğimizi taahhüt ederiz.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.banner {
  width: 100%;
  min-width: auto;
  position: relative;
  overflow: hidden;
}
.about {
    background-image: url("../assets/img/slider1rotate.png");
}
@media screen and (min-width: 1900px) {
  .about-text {
    padding: 0 150px;
  }
}
</style>