<template>
  <!-- <div id="map" class="map"><img :src="require('../assets/img/map.png')" alt=""></div> -->
  <div id="mapContainer" class="map"></div>
</template>

<script>
import mapboxgl from "mapbox-gl";

export default {
  data() {
    return {
      accessToken:
        "pk.eyJ1IjoibWxlbHlha2FuIiwiYSI6ImNrbHAxZnl3aTBoZjIybm53bHhzMndiYmIifQ.-qjZQIDROCcyIjnG8foMLg",
    };
  },
  mounted() {
    mapboxgl.accessToken = this.accessToken;

    const monument = [37.03466, 39.73806];

    const map = new mapboxgl.Map({
      container: "mapContainer",
      style: "mapbox://styles/mapbox/streets-v11",
      center: monument,
      zoom: 15,
    });

    const nav = new mapboxgl.NavigationControl();
    map.addControl(nav, "top-right");

    // new mapboxgl.Popup({ closeOnClick: false })
    //   .setLngLat(monument)
    //   .setHTML("Detay Teknoloji</h1>")
    //   .addTo(map);
    const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
      "<div style='display:flex'><img src='" +
        require("../assets/img/DTYBina.png") +
        "'style='width: 50px;'/><div style='margin-left:10px'><h3 style='font-weight: bold;font-size: 1.17em;'>Detay Teknoloji ARGE Merkezi</h3><p>Kardeşler Mahallesi 51-52. Sokak No: 4A</p></div></div>"
    );

    // create DOM element for the marker
    const el = document.createElement("div");
    el.setAttribute("class", "marker2");

    // create the marker
    new mapboxgl.Marker()
      .setLngLat(monument)
      .setPopup(popup) // sets a popup on this marker
      .addTo(map);
  },
};
</script>

<style lang="scss">
.map {
  width: 100%;
  height: 300px;
}
.marker2 {
  background-image: url("../assets/img/DTYBina.png");
  background-size: cover;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}
.mapboxgl-popup {
  max-width: 200px;
}
button:focus {
  outline: none;
}
.mapboxgl-popup-close-button {
  margin-right: 5px;
  font-size: 20px;
}
</style>