let oExports = {};
oExports.getFactoryInformations = () => {
    var oFactoryInfoBoxes = [
        {
            "SvgSrc": "website.svg",
            "Title": "+1000 Başarılı Proje",
            "Description": "Bugüne kadar binden fazla projeyi başarıyla tamamlayarak, müşterilerimizin iş süreçlerini teknolojiyle buluşturduk."
        }, {
            "SvgSrc": "increase.svg",
            "Title": "+30 Sektörel Uzmanlık",
            "Description": "30'dan fazla sektördeki uzmanlığımızla, eşsiz bir deneyime sahibiz."
        }, {
            "SvgSrc": "handshake.svg",
            "Title": "+300 Aktif Müşteri",
            "Description": "Sektörünün öncüsü 300'den fazla aktif müşteriye hizmet veriyoruz."
        }, {
            "SvgSrc": "networking.svg",
            "Title": "+500 Yetkin Çalışan",
            "Description": "İstanbul genel müdürlük ve Sivas ofislerinde çalışan 500 kişilik deneyimli bir ekibe sahibiz."
        }, {
            "SvgSrc": "badge.svg",
            "Title": "+40 Ödül",
            "Description": "Son yıllarda peş peşe aldığımız ulusal ve uluslararası ödüllerle gücümüze güç katıyoruz."
        }, {
            "SvgSrc": "customer-service.svg",
            "Title": "+100 Destek Danışmanı",
            "Description": "Alanında uzman destek danışmanlarımızla, tüm ihtiyaçlarınıza yanıt vermeye hazırız."
        }
    ];
    return oFactoryInfoBoxes;
};
export default oExports;
