<template>
  <v-row class="footer my-0 white--text px-16 py-5">
    <v-container>
      <v-row class="footer-info">
        <v-col class="footer-logo">
          <v-img
            max-width="250"
            :src="require('../assets/img/DetayTeknoloji_WhiteLogo.png')"
            class="mb-1"
          ></v-img>

          <p>Teknoloji Danışmanlığı Şirketi</p></v-col
        >
        <v-col class="d-flex justify-end text-end social-media">
          <div class="social-media flex flex-col text-right">
            <div class="bg-ye">
              <p>Bizi Takip Edin</p>
              <div class="mt-2">
                <i class="fab fa-instagram icon"></i>
                <i class="fab fa-twitter icon"></i>
                <i class="fab fa-facebook icon"></i>
                <i class="fab fa-linkedin icon"></i>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-row>
</template>


<script>
export default {};
</script>

<style scoped lang="scss">
.footer {
  background-color: #061b4a;
  .icon {
    color: #3b82f6;
    margin-right: 6px;
    padding: 8px;
    background: white;
    border-radius: 100%;
    font-size: 24px;
    &:first-child {
      color: #ef4444;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

@media screen and (min-width: 1900px) {
  .footer-info {
    padding: 0 130px;
  }
}
@media screen and (max-width: 600px) {
  .footer-info {
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important;
    .footer-logo {
      display: flex;
      flex-direction: column;
      align-items: center !important;
      justify-content: center !important;
    }
  }
  .social-media {
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
    padding: 0;
    div {
      p {
        text-align: center;
      }
    }
  }
}
</style>